import React from 'react'

export default () => (
  <>'
    <script>
    {
      `
      function op(obj) {
        const x=document.getElementById(obj);
        if(x.style.display == "none") x.style.display = "block";
        else x.style.display = "none"
      }
      `
    }
    </script>
    <div id="WEBzona" style={{display: 'none'}}>
      <table>
        <tbody>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Stomatologia Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Medycyna Estetyczna Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Dermatologia Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Kosmetologia Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Dietetyka Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Lekarz Stomatolog Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Lekarz Medycyny Estetycznej Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Lekarz Dermatolog Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Lekarz Kosmetolog Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Lekarz Dietetyk Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Kosmetolog Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Dietetyk Świdnica</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Stomatologia</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Medycyna Estetyczna</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Dermatologia</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Kosmetologia</span></h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4><span style={{fontSize: '8pt'}}>Dietetyka</span></h4>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </>
)
