import { Link, withPrefix } from "gatsby"
import React from "react"

const Shortcut = () => {
  return (
    <div className="row">
      <div className="large-10 medium-12 small-12 columns show-for-large">
        <div className="na-skroty">
          <div className="row">
            <div className="large-2 medium-3 small-12 columns">
              <h5>Na skróty:</h5>
            </div>
            <div className="large-10 medium-9 small-12 columns">
              <div className="row">
                <div className="large-4 medium-12 small-12 columns text-left large-text-center">
                  <p>
                    <Link
                      to={"/kontakt"}
                      className="ga_send_open"
                      data-open="bonpodarunkowy"
                    >
                      <span>
                        <img className="svg"
                             src={withPrefix("images/phone.svg")}
                             alt="Ikona telefon" />
                      </span>
                      Umów się na wizytę
                    </Link>
                  </p>
                </div>
                <div className="large-4 medium-12 small-12 columns text-left large-text-right">
                  <p>
                    <Link
                      to={"/oferta/stomatologia/ortodoncja"}
                      className="ga_send_open"
                      data-open="bonpodarunkowy"
                    >
                      <span>
                        <img className="svg"
                             src={withPrefix("images/zabiegi.svg")}
                             alt="Ikona zabiegi" />
                      </span>
                      Zobacz dostępne zabiegi
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Shortcut
