import { withPrefix } from "gatsby"

import React from "react"
import { Helmet } from "react-helmet"

import Shortcut from "../components/homepage/shortcut"

import Layout from "../components/layout"
import Members from "../components/aboutUs/members"
import HeaderWhitenav from "../components/header-whitenav"
import TableOfContents from "../components/aboutUs/table-of-contents"
import SEO from "../components/seo";


const AboutUs = () => (
  <Layout>
    <HeaderWhitenav />
    <SEO
      title={'Lekarze specjaliści Centrum Medycznego Dent&Beauty | Lekarz Świdnica'}
      description={'Dent & Beauty to pierwsza klinika w Świdnicy łącząca specjalności – stomatologię, medycynę estetyczną oraz dietetykę. '}
    />
    <div id="pagewrapper">
      <Shortcut />

      <section className="section padding-top60">
        <div className="row">
          <div className="large-5 medium-12 small-12 columns">
            <h4 className="border-bottom">Lekarze skupieni na pięknie.</h4>
            <div className="font-mobile">
              <p>
                Dent & Beauty to pierwsza klinika w Świdnicy łącząca specjalności – stomatologię, medycynę estetyczną oraz dietetykę. Kompleksowy profil działania sprawia, że Pacjenci mogą wymagać od nas więcej, a my dzięki medycznej wiedzy i doświadczeniu jesteśmy w stanie zagwarantować najwyższą jakość usług.
              </p>
              <p>
                Podstawowym elementem naszej działalności jest stomatologia. Stworzyliśmy zespół, dzięki któremu możemy mówić o całościowym podejściu do pięknego uśmiechu. Oferujemy usługi z zakresu stomatologii zachowawczej, chirurgi szczękowej i stomatologicznej oraz implantologii.
              </p>
              <p>
                Dzisiejszy kanon piękna wymaga od nas stałej pielęgnacji twarzy i ciała. Zabiegi medycyny estetycznej pozwalają spowolnić proces starzenia, usunąć niedoskonałości oraz poprawić kondycje naszej skóry. Najnowszy sprzęt i profesjonalizm naszych specjalistów potrafią zdziałać cuda, utrzymując urodę pacjentów w świetnej formie przez długie lata.
              </p>
              <p>
                Dopełnieniem naszej oferty są porady dietetyka, który indywidualnie dopasuje dietę do Twoich potrzeb. Wierzymy w efekty współpracy specjalistów różnych dziedzin z lekarzami. Filozofia kompleksowej opieki przekłada się bezpośrednio na rezultaty oraz zadowolenie naszych pacjentów.
              </p>
            </div>
          </div>

          <div className="large-5 columns show-for-large">
            <div className="video-ramka photobg shadow"
                 style={{backgroundImage: `url(${withPrefix('images/o-nas/movie-background.jpg')}`}}>

              <a href="#" data-popup="#onas_1" className="popupopen all"></a>
              <a href="#" data-popup="#onas_1" className="popupopen play shadow"><i className="fa fa-play"
                                                                                    aria-hidden="true"></i></a>
            </div>
          </div>
        </div>

        <div className="row collapse hide-for-large">
          <div className="medium-12 small-12 columns">
            <div className="video-ramka photobg shadow"
                 style={{backgroundImage: `url(${withPrefix('images/o-nas/movie-background.jpg')}`}}>
              <a href="#" data-popup="#onas_1" className="popupopen all"></a>
              <a href="#" data-popup="#onas_1" className="popupopen play shadow"><i className="fa fa-play"
                                                                                    aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </section>
      <Members />
    </div>
    <div className="popup-container" id="onas_1" data-ytid='7TAza4L7WXY'>
      <div className="popup-element">
        <span className="popup-close" data-popup="#onas_1">×</span>
        <iframe frameBorder="0" allowFullScreen></iframe>
      </div>
    </div>
    <TableOfContents />
  </Layout>
)

export default AboutUs
