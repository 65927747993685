import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"
import React from "react"

const Members = () => {
  const { strapiAboutUs } = useStaticQuery(graphql`
      query {
          strapiAboutUs {
              members {
                  specialization
                  title
                  description
                  image {
                      childImageSharp {
                          fluid(maxWidth: 1920, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
          }
      }
  `)

  const { members } = strapiAboutUs

  const membersForLargeScreensJsx = members.map((member: any, index: number) => {
    const url = member.image.childImageSharp.fluid.src

    return (
      <div className="large-6 medium-12 small-12 columns person-box" key={index}>
        <div className="margin-top80">
          <div className="row" data-equalizer="osoba">
            <div className="large-4 columns">
              <div className="display-table" data-equalizer-watch="osoba">
                <div className="display-table-cell v-top">
                  <div className="osoba-photo photobg"
                       style={{ backgroundImage: `url(${url})` }}>
                  </div>
                </div>
              </div>
            </div>
            <div className="large-8 columns">
              <div className="display-table" data-equalizer-watch="osoba">
                <div className="display-table-cell v-top">
                  <p className="margin-0">
                    <small className="big gray2"><strong>{member.specialization}</strong></small>
                  </p>
                  <h6 className="normal big margin-bottom20"><strong>{member.title}</strong></h6>
                  <p>
                    {member.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })

  const membersSliderJsx = members.map((member: any, index: number) => {
    const url = member.image.childImageSharp.fluid.src

    return (
      <div className="gallery-cell" key={index}>
        <div className="kadra photobg"
             style={{ backgroundImage: `url(${url})` }}>
          <a className="slider_person" href="#" data-spec={member.specialization}
             data-person={member.title}
             data-description={member.description}></a>
        </div>
      </div>
    )
  })

  return (
    <section className="section padding-top20 persons">
      <div className="row show-for-large">
        {membersForLargeScreensJsx}
      </div>

      <div className="kadra-slider hide-for-large margin-top0">
        {membersSliderJsx}
      </div>

      <div className="hide-for-large margin-bottom80">
        <div className="row">
          <div className="medium-12 small-12 columns">
            <p className="margin-0 gray2" id="slider_spec">{members[0].specialization}</p>
            <h6 className="normal margin-bottom20 font-18"><strong
              id="slider_person">{members[0].title}</strong></h6>
            <div className="font-mobile" id="slider_description">{members[0].description}
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Members
